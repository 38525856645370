import React, { Component } from 'react';
import Card from 'components/dumb/Card/Card';
import Form from 'components/dumb/Form/Form';
import Button from 'components/dumb/Button/Button';
import Dialog from 'components/smart/Dialog/Dialog';
import LanguageChoose from 'components/smart/LanguageChoose/LanguageChoose';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import './Login.scss';
import logo from 'logo.svg';
import qs from 'query-string';

@inject("ui", "lang", "auth", "organizations") 
@observer
class Login extends Component {

    submit = async (data) => {

        if(this.props.ui.isModalOpen || this.props.ui.isLoading) {
            return false;
        }

        const res = await this.props.auth.login(data.email, data.password, data.mfauth_password);

        if(this.props.auth.isResponseFailed(res)) {
            let errors = this.props.lang.getErrorMessages(res);
            this.props.ui.openModal(<Dialog type="alert" title={this.props.lang.get.dialog.error} subtitle={this.props.lang.get.login.title} description={errors} animation="error"/>);
        }

        if(this.props.auth.isResponseOk(res)) {

            if(!res.data.jwt) {

                this.props.ui.openModal(<Dialog type="prompt-text" autoComplete="one-time-code" title={this.props.lang.get.login.mfauth.title} mandatory={true} subtitle={this.props.lang.get.login.mfauth.subtitle} onSubmit={(mfauth_password) => {

                    data.mfauth_password = mfauth_password;
                    return this.submit(data);

                }}/>);

                return false;
            }
        }
    }

    render() {

        const params = qs.parse(this.props.location.search);
        const logoUrl = (params && params.organizations_id && this.props.organizations.isValidOrganizationsId(params.organizations_id)) ? this.props.organizations.getLogoUrl(params.organizations_id): logo;
        const forgotUrl = (params && params.organizations_id && this.props.organizations.isValidOrganizationsId(params.organizations_id)) ? "/forgot?organizations_id=" + params.organizations_id : "/forgot";

        return (

            <div className="Login">

                <Card>

                    <img className="logo" src={logoUrl} alt={this.props.lang.get.common.name}/>

                    <Form onSubmit={this.submit} submitOnEnter={true}>

                        <Form.Input autoFocus required type="email" name="email" autoComplete="username" id="email" label={this.props.lang.get.login.email}/>
                        <Form.Input required type="password" name="password" autoComplete="current-password" id="password" label={this.props.lang.get.login.password}/>
                        <Form.Submit><Button color="grey" icon="lock" text={this.props.lang.get.login.login}/></Form.Submit>

                        <Link to={forgotUrl}>{this.props.lang.get.login.forgot}</Link>

                    </Form>

                    <LanguageChoose/>

                </Card>

            </div>

        );

    }
}

export default Login;

