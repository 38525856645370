import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Panel from 'components/dumb/Panel/Panel';
import Navi from 'components/dumb/Navi/Navi';
import { Link } from 'react-router-dom';
import logoImg from 'logo.svg';
import './Menu.scss';

@inject("ui", "lang", "profile", "organizations", "teams", "whistleblowers")
@observer
class Menu extends Component {

    componentDidMount() {

        if(this.props.teams.isSomethingLoaded === false) {
            this.props.teams.get();
        }

        if(this.props.whistleblowers.isSomethingLoaded === false) {
            this.props.whistleblowers.get();
        }
    }

    openSupport = async () => {
        
        const url = await this.props.profile.zendesk();

        if(url) {
            const w = window.open();
            w.location.replace(url);
        }
    };

    render() {

        // logo
        const logoSrc = this.props.organizations.logo || logoImg;
        const logo = <img className="logo" src={logoSrc} alt={this.props.lang.get.common.name}/>;
    
        // for everyone in every setup
        const dashboard = <Link to="/"><Navi icon="dashboard" title={this.props.lang.get.menu.home}/></Link>;
        const customers = <Link to="/customers"><Navi icon="group" title={this.props.lang.get.menu.customers}/></Link>;
        const filters = <Link to="/filters"><Navi icon="star" title={this.props.lang.get.menu.filters}/></Link>;
        const search = <Link to="/search"><Navi icon="search" title={this.props.lang.get.menu.search}/></Link>;
        const removedcustomers = <Link to="/removedcustomers"><Navi icon="delete" title={this.props.lang.get.menu.removedcustomers}/></Link>;
        const announcements = <Link to="/announcements"><Navi icon="announcement" title={this.props.lang.get.menu.announcements}/></Link>;

        const manualLink = this.props.lang.language === "fi" ? "doks_kayttoohjeet.pdf" : "doks_userguide.pdf";
        const manual = <a target="_blank" rel="noopener noreferrer" href={"/" + manualLink}><Navi icon="menu_book" title={this.props.lang.get.menu.manual}/></a>;

        const support = <Navi icon="help_center" title={this.props.lang.get.menu.support} onClick={this.openSupport}/>;

        // for everyone, if is ON
        const tasks = this.props.organizations.my.use_tasks === true && <Link to="/tasks"><Navi icon="checklist" title={this.props.lang.get.menu.tasks}/></Link>;
        const alerts = this.props.organizations.my.use_alerts === true && <Link to="/alerts"><Navi icon="warning" title={this.props.lang.get.menu.alerts}/></Link>;
        const queries = this.props.organizations.my.use_queries === true && <Link to="/queries"><Navi icon="policy" title={this.props.lang.get.menu.queries}/></Link>;
        const pending = (this.props.organizations.my.use_requests === true || this.props.organizations.my.use_identifications === true || this.props.organizations.my.use_esignatures === true || this.props.organizations.my.use_letters === true) && <Link to="/pending"><Navi icon="history" title={this.props.lang.get.menu.pending}/></Link>;
        const todos = this.props.organizations.my.use_todos === true && <Link to="/todos"><Navi icon="grading" title={this.props.lang.get.menu.todos}/></Link>;

        // for admins in every setup
        const settings = this.props.profile.me.is_admin === true && <Link to="/settings"><Navi icon="settings" title={this.props.lang.get.menu.settings}/></Link>;

        // for everybody that has access to at least 1 whistleblower
        const whistleblowers = this.props.whistleblowers.cnt > 0 && <Link to="/whistleblowers"><Navi icon="hail" title={this.props.lang.get.menu.whistleblowers}/></Link>;

        // sections
        const section1 = (dashboard || customers || pending) && (<Panel.SectionGroup><Panel.Section>
            {dashboard}
            {customers}
            {filters}
            {tasks}
            {pending}
            {todos}
        </Panel.Section></Panel.SectionGroup>);

        const section3 = (alerts || queries) && (<Panel.SectionGroup><Panel.Section>
            {alerts}
            {queries}
        </Panel.Section></Panel.SectionGroup>);

        const section5 = (settings) && (<Panel.SectionGroup><Panel.Section>
            {settings}
        </Panel.Section></Panel.SectionGroup>);

        const section2 = (search || removedcustomers) && (<Panel.SectionGroup><Panel.Section>
            {search}
            {removedcustomers}
        </Panel.Section></Panel.SectionGroup>);

        const section6 = (whistleblowers) && (<Panel.SectionGroup><Panel.Section>
            {whistleblowers}
        </Panel.Section></Panel.SectionGroup>);
    
        const section7 = (announcements || manual || support) && (<Panel.SectionGroup><Panel.Section>
            {announcements}
            {manual}
            {support}
        </Panel.Section></Panel.SectionGroup>);

        return (

            <Panel title={this.props.lang.get.menu.title} open={this.props.ui.isMenuOpen} size="small" position="left" onClick={this.props.ui.closeMenu} onClose={this.props.ui.closeMenu} className="Menu">

                {section1}
                {section2}
                {section3}
                {section5}
                {section6}
                {section7}
                {logo}

            </Panel>

        );

    }
}

export default Menu;

