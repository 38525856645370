import ApiStore from './ApiStore';
  
export default class DoksApiStore extends ApiStore {

/*
    // Interception
    intercept = (response) => {

        if("errors" in response) {

            response.errors.forEach((el) => {

                if("code" in el) {
                    if(el.code.indexOf("TOKEN_VERIFICATION_FAILED") !== -1) {
                        window.location.reload(true);
                    }
                }
            });
        }

        return response;

    }
*/

}
