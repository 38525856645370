import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class TodoStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.todos.customer;
        this.customers_id = false;
        this.sort = "created_at";
    }

    @action.bound setCustomersId(id) {

        if(id === this.customers_id) {
            return true;
        }

        this.customers_id = id;
        this.data.clear();
        this.url = api.endpoints.user.todos.customer.replace(":id", id);

    }

    @action.bound async getMy() {

        const res = await this.root.api.get(
            {},
            {url: this.prepareUrl(api.endpoints.user.todos.user)}
        );

        return res;
    }

    @action.bound async send(id) {

        const res = await this.post(
            {},
            {url: this.prepareUrl(this.url + "/" + id + "/send")}
        );

        return res;
    }

    @action.bound async answer(id) {

        const res = await this.post(
            {},
            {url: this.prepareUrl(this.url + "/" + id + "/answer")}
        );

        return res;
    }

    async file(id, files_id) {

        let letter = this.data.get(id);

        if(letter === false) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.files + "/" + files_id + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    @action.bound async removeFile(id, files_id) {

        const res = await this.patch(
            {"files_id": files_id},
            {url: this.prepareUrl(this.url + "/" + id)}
        );

        return res;
    }

}
