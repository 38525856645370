import React from 'react';
import './Card.scss';

const Card = (props) => {

    let className = "Card";
    if(props.className) {
        className += ' ' + props.className;
    }

    let image = props.image && <div className="CardImage">{props.image}</div>;
    let title = props.title && <div className="CardTitle">{props.title}</div>;
    let subtitle = props.subtitle && <div className="CardSubTitle">{props.subtitle}</div>;
    let content = <div className="CardContent">{props.description}{props.children}</div>;
    let footer = props.footer && <div className="CardFooter">{props.footer}</div>;

    return (
        <div className={className}>

            {image}
            {title}
            {subtitle}
            {content}
            {footer}

        </div>
    );

}

export default Card;


