import DoksApiStore from './DoksApiStore';
import api from 'api.json';

export default class PriceStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.admin.prices;
        this.sort = "";
    }
}
