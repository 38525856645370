import DoksApiStore from './DoksApiStore';
import { action, runInAction } from 'mobx';
import api from 'api.json';

export default class AlertStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.alerts.all;
        this.customers_id = false;
        this.sort = "description";
    }
        
    // All alert types
    types = {
        "sanction": [
            "SANCTION",
            "IDENT_SANCTION",
            "OWNER_SANCTION",
            "ACTUALBENEFICIARY_SANCTION",
            "RESPONSIBLEPERSON_SANCTION",
            "PEP_SANCTION",
            "SIGNATORY_SANCTION",
            "UNIQUENAME_SANCTION"
        ],

        "pepscreening": [
            "PEPSCREENING",
            "IDENT_PEPSCREENING",
            "OWNER_PEPSCREENING",
            "ACTUALBENEFICIARY_PEPSCREENING",
            "RESPONSIBLEPERSON_PEPSCREENING",
            "SIGNATORY_PEPSCREENING",
            "UNIQUENAME_PEPSCREENING"
        ],

        "mismatch": [
            "IDENT_MISMATCH",
            "SIGNATORY_MISMATCH"
        ],

        "other": [
            "DOC",
            "RISKASSESSMENT"
        ]
    };

    allTypes = [...this.types.sanction, ...this.types.pepscreening, ...this.types.mismatch, ...this.types.other];

    @action.bound setCustomersId(id) {

        if(id === this.customers_id) {
            return true;
        }

        this.customers_id = id;

        if(id) {
            this.data.forEach((el) => {
                if(el.customers_id === id) {
                    this.data.delete(id);
                }
            });
        } else {
            this.data.clear();
        }

        if(id) {
            this.url = api.endpoints.user.alerts.customer.replace(":id", id);
        } else {
            this.url = api.endpoints.user.alerts.all;
        }
    }

    async dsa(data) {

        if(!data) {
            return false;
        }

        if(!data.metadata) {   
            return false;
        }

        if(!data.metadata.dsaId) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.dsa + "/" + data.metadata.dsaId + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    async dpa(data) {

        if(!data) {
            return false;
        }

        if(!data.metadata) {
            return false;
        }

        if(!data.metadata.dpaId) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.dpa + "/" + data.metadata.dpaId + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    @action.bound async unsilence(id) {

        const res = await this.delete(
            {},
            {url: this.prepareUrl(this.url + "/" + id + "/silence")}
        );

        return res;
    }

    @action.bound async silence(id, description, valid_at, silenceclass) {

        const res = await this.post(
            {"description": description, "valid_at": valid_at, "silenceclass": silenceclass},
            {"url": this.prepareUrl(this.url + "/" + id + "/silence")}
        );

        return res;
    }

    @action.bound async refresh() {

        if(!this.customers_id) {      
            return false;
        }

        const res = await this.patch();

        if(this.isResponseOk(res)) {

            if(res.data instanceof Array) {

                runInAction(() => {
                    this.data.clear();
                    res.data.map( (item) => {
                        this.data.set(item.id, item);
                        return true;
                    })
                });

            } 
        }

        return res;
    }

    getUrl(data) {

        let url = "/customers/" + data.customers_id;

        switch(data.type) {

            case "IDENT_MISMATCH":
            case "IDENT_SANCTION":
            case "IDENT_PEPSCREENING":
            url += "/identifications/" + data.target_id;
            break;

            case "SIGN_MISMATCH":
            case "SIGN_SANCTION":
            case "SIGN_PEPSCREENING":
            url += "/signatures/" + data.target_id;
            break;

            case "SIGNATORY_MISMATCH":
            case "SIGNATORY_SANCTION":
            case "SIGNATORY_PEPSCREENING":
            // split target id and add signatories
            url += "/esignatures/" + data.target_id.replace("/", "/signatories/");
            break;

            case "OWNER_SANCTION":
            case "OWNER_PEPSCREENING":
            url += "/owners/" + data.target_id;
            break;

            case "ACTUALBENEFICIARY_SANCTION":
            case "ACTUALBENEFICIARY_PEPSCREENING":
            url += "/actualbeneficiaries/" + data.target_id;
            break;

            case "RESPONSIBLEPERSON_SANCTION":
            case "RESPONSIBLEPERSON_PEPSCREENING":
            url += "/responsiblepersons/" + data.target_id;
            break;

            case "PEP_SANCTION":
            url += "/peps/" + data.target_id;
            break;

            case "SANCTION":
            case "PEPSCREENING":
            url += "/update";
            break;

            case "DOC":
            url += "/documents/" + data.target_id;
            break;

            case "RISKASSESSMENT":
            url += "/riskassessments/" + data.target_id;
            break;

            case "UNIQUENAME_SANCTION":
            case "UNIQUENAME_PEPSCREENING":
            url = "";
            break;

            default:
        }

        return url;
    }
    
}
