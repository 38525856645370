import React, { Component } from 'react';
import Dialog from 'components/smart/Dialog/Dialog';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { withRouter } from 'react-router-dom';

@inject("ui", "lang", "auth", "organizations", "announcements", "profile", "alerts", "customers")
@observer
@withRouter
class Run extends Component {

    // Scheduler
    componentDidMount() {

        // Run only after login
        if(this.props.auth.isSessionResumed === false) {

            // Run once when organization is loaded and customers are loaded
            const organizationAndCustomersAreLoaded = reaction(() => {
                return this.props.customers.isSomethingLoaded && this.props.customers.cnt > 1 && this.props.organizations.my !== false;
            }, async () => {

                await this.askToShowAlerts();
                organizationAndCustomersAreLoaded();        // disposer
            });

            // Run once when organization is loaded
            const organizationIsLoaded = reaction(() => {
                return this.props.organizations.my !== false;
            }, async () => {

                await this.askToShowAnnouncements();
                organizationIsLoaded();                     // disposer
            });
        }
    }

    // Announcements
    askToShowAnnouncements = async () => {

        if(this.props.history.location.pathname.indexOf("/announcements") === -1) {

            const announcements = await this.props.announcements.get();

            if(this.props.announcements.isResponseOk(announcements)) {

                if(this.props.announcements.latest_at > this.props.profile.me.prevlogin_at) {

                    this.props.ui.openModal(<Dialog type="confirm" title={this.props.lang.get.announcements.title} description={this.props.lang.get.announcements.confirm} confirm={this.props.lang.get.announcements.yes} cancel={this.props.lang.get.announcements.no} onConfirm={() => {

                        this.props.history.push("/announcements");

                    }}/>);
                }
            }
        }
    }

    // Alerts
    askToShowAlerts = async () => {

        if(this.props.history.location.pathname.indexOf("/alerts") === -1) {

            if(this.props.organizations.my.use_alerts === true) {

                await this.props.alerts.setCustomersId(false);
                const alerts = await this.props.alerts.get();

                if(this.props.alerts.isResponseOk(alerts)) {

                    // Get number of alerts
                    const acnt = this.props.alerts.list.filter((el) => {

                        if(el.silence !== false) {
                            return false;
                        }

                        const customer = this.props.customers.data.get(el.customers_id);
                        return customer && customer.is_removed === false;

                    }).length;

                    if(acnt > 0) {

                        this.props.ui.openModal(<Dialog type="confirm" title={this.props.lang.get.alerts.title} subtitle={this.props.lang.replace(this.props.lang.get.alerts.subtitle, {cnt: acnt})} description={this.props.lang.get.alerts.confirm} confirm={this.props.lang.get.alerts.yes} cancel={this.props.lang.get.alerts.no} animation="warning" onConfirm={() => {

                            this.props.history.push("/alerts");

                        }}/>);
                    }
                }
            }
        }
    }

    shouldComponentUpdate() {
        return false;       // never update on any changes
    }

    render() {
        return null;        // never render anything
    }
}

export default Run;

