import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class TaskStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.tasks;
        this.sort = "name";
    }

    // Alias for POST
    @action.bound add(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.tasks;
        return this.post(data, settings);
    }

    // Alias for PUT
    @action.bound update(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.tasks + "/" + data.id;
        return this.put(data, settings);
    }

    // Alias for DELETE
    @action.bound remove(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.tasks + "/" + data.id;
        return this.delete(data, settings);
    }
}
