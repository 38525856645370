import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/smart/App/App';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import Stores from 'stores';
import './reset.css';
import { configure } from 'mobx';

configure({
    enforceActions: "observed",
   // computedRequiresReaction: true,
  //  reactionRequiresObservable: true,
//    observableRequiresReaction: true,
 //   disableErrorBoundaries: true
});

// Stores
const stores = new Stores();

ReactDOM.render(
        
        <React.StrictMode>
            <Provider {...stores}>
                <BrowserRouter basename="/app">
                    <App/>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>

, document.getElementById('root'));
