import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class PreferenceStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.preferences;
        this.sort = "type";
        this.sort2 = "name";
    }

    // Alias for PUT
    @action.bound update(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.preferences + "/" + data.id;
        return this.put(data, settings);
    }

    @action.bound reset(id) {
        let settings = {};
        settings.url = api.endpoints.admin.preferences + "/" + id;
        return this.put({"name": "", "value": null}, settings);
    }
}

