import DoksApiStore from './DoksApiStore';
import { observable, action, computed, runInAction } from 'mobx';
import api from 'api.json';

export default class AuthStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.auth;
        this.autoLogoutTimeout = null;

        runInAction(() => {
            this.jwt = localStorage.getItem("jwt") || "";
            this.id = localStorage.getItem("id") || false;
            this.isSessionResumed = this.id ? true : false;
        });
    
    }

    @observable jwt = "";
    @observable id = false;
    @observable isSessionResumed = false;    // this flag indicates if user has saved id in localstorage in first place or not

    // Function to reset timeout for auto-logout. Triggers from API calls.
    resetAutoLogoutTimeout = () => {  
    
        // Default timeout is 15 minutes
        let timeout = 1800 * 1000;

        // Use user specific timeout, if present
        if(this.root.profile && this.root.profile.me && this.root.profile.me.autologout_timeout && this.root.profile.me.autologout_timeout > 0) {
            timeout = this.root.profile.me.autologout_timeout * 1000;
        }

        // Reset timeout if it is already set
        if(this.autoLogoutTimeout) {
            clearTimeout(this.autoLogoutTimeout);
        }

        // Set timeout
        this.autoLogoutTimeout = setTimeout(() => {
            this.logout();
        }, timeout);
    };

    @action.bound logout() {

        this.setJwt("");
        this.setId(false);
        window.location.reload(true);
        window.location.href = "/";
    }

    @action.bound setJwt(jwt) {

        this.jwt = jwt;
        localStorage.setItem("jwt", this.jwt);
    }

    @action.bound setId(id) {

        this.id = id;

        if(id) {
            localStorage.setItem("id", this.id);
        } else {
            localStorage.removeItem("id");
        }
    }

    @computed get isLoggedIn() {
        return (this.jwt === "") ? false : true;
    }

    @action.bound async login(email, password, mfauth_password) {

        const res = await this.post(
            {"email": email, "password": password, "mfauth_password": mfauth_password},
            {renew: false}
        );

        if(this.isResponseOk(res)) {

            if(res.data.jwt) {
                this.setJwt(res.data.jwt);
                this.setId(res.data.id);
            }
        }

        return res;
    }

    @action.bound async forgot(email) {

        const res = await this.patch(
            {"email": email}
        );

        return res;
    }

    @action.bound async renew() {

        const res = await this.put(
            {},
            {renew: false}
        );

        if(this.isResponseOk(res)) {
            this.setJwt(res.data.jwt);
            this.setId(res.data.id);
        } else {
            this.logout();
        }

        return res;
    }

    @action.bound async temporary() {

        const res = await this.put(
            {"lifetime": 60}
        );

        return res;
    }

}
