import React from 'react';
import Flex from 'components/dumb/Flex/Flex';
import Icon from 'components/dumb/Icon/Icon';
import './Navi.scss';

const Navi = (props) => {

    let className = "Navi";

    if(props.className) {
        className += ' '+props.className;
    }

    if(props.noClick) {
        className += ' noClick';
    }

    let progress;
    if(props.progress >= 0) {
        progress = <div className="NaviProgress"><div className="NaviMeter" style={{width: props.progress+"%"}}/></div>;
    }   

    let title = props.title && <h4 className="NaviTitle">{props.title}</h4>;
    let subtitle = (props.subtitle || props.subtitle === 0) && <h5 className="NaviSubTitle">{props.subtitle}</h5>;
    let info = props.info && <h5 className="NaviInfo">{props.info}</h5>;
    let titles = <Flex.Column size="max">{title}{subtitle}{info}{progress}</Flex.Column>;    
    let iconLeft = props.icon && <Flex.Column size="min"><Icon className="NaviIcon" name={props.icon}/></Flex.Column>;
    let iconRight = "";
    let style = props.indent > 0 ? {marginLeft: props.indent + "em"} : {};

    if(props.iconPosition === "right") {
        iconRight = props.icon && <Flex.Column size="min"><Icon className={"NaviIcon" + (props.icon === "keyboard_arrow_right" ? " animate" : "")} name={props.icon}/></Flex.Column>;
        iconLeft = "";
    }

    return <Flex style={style} noWrap align="center" onClick={props.onClick} className={className}>{iconLeft}{titles}{iconRight}</Flex>;
}

export default Navi;

