import React from 'react';
import './Icon.scss';

const Icon = (props) => {

    let classNames = "Icon material-icons";

    if(props.className) {
        classNames += ' '+props.className;
    }

    return <i onClick={props.onClick} className={classNames}>{props.name}</i>;
}

export default Icon;

