import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class SignatureStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.signatures;
        this.customers_id = false;
        this.sort = "created_at";
    }

    @action.bound setCustomersId(id) {

        if(id === this.customers_id) {
            return true;
        }

        this.customers_id = id;
        this.data.clear();
        this.url = api.endpoints.user.signatures.replace(":id", id);

    }

    async file(id, files_id) {

        let signature = this.data.get(id);

        if(signature === false) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.files + "/" + files_id + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    async summary(id, language) {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(this.url + "/" + id + "/pdf?jwt=" + encodeURIComponent(res.data.jwt) + "&l=" + language);
        }

        return false;
    }

    @action.bound async send(id) {

        const res = await this.post(
            {},
            {url: this.prepareUrl(this.url + "/" + id + "/send")}
        );

        return res;
    }

    @action.bound async removeFile(id, files_id) {

        const res = await this.patch(
            {"files_id": files_id},
            {url: this.prepareUrl(this.url + "/" + id)}
        );

        return res;
    }

    preview(id) {

        const url = this.prepareUrl(api.endpoints.preview.signatures.replace("{{host}}", this.root.organizations.my.host).replace(":organizations_id", this.root.organizations.id).replace(":customers_id", this.customers_id).replace(":id", id));
        
        window.open(url);
    }

    publicUrl(id) {
        return this.prepareUrl(api.endpoints.publicUrls.signatures.replace("{{host}}", this.root.organizations.my.host).replace(":organizations_id", this.root.organizations.id).replace(":customers_id", this.customers_id).replace(":id", id));
    }
}
