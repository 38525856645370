import DoksApiStore from './DoksApiStore';
import api from 'api.json';

export default class PendingStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.pending;
    }

}
