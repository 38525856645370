import React from 'react';
import { createPortal } from 'react-dom';

const portalTo = (domId) => (Comp) => {

    return class extends React.Component {

        constructor(props) {

            super(props);
    
            this.root = document.body;
            this.container = document.createElement('div');
        }

        componentDidMount() {
            this.root = (domId) ? document.getElementById(domId) : document.body;
            this.root.appendChild(this.container);
        }

        componentWillUnmount() {
            this.root.removeChild(this.container);
        }

        render() {
            return createPortal(<Comp {...this.props}/>, this.container)
        }

    }

}

class Portal extends React.Component {

    constructor(props) {

        super(props);
    
        this.root = document.body;
        this.container = document.createElement('div');
    }

    componentDidMount() {
        this.root = (this.props.target) ? document.getElementById(this.props.target) : document.body;
        this.root.appendChild(this.container);
    }

    componentWillUnmount() {
        this.root.removeChild(this.container);
    }

    render() {
        return createPortal(this.props.children, this.container)
    }
}

export default Portal;
export { portalTo };
