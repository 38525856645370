import { observable, action, computed } from 'mobx';
import lang_fi from 'lang.fi.json';
import lang_en from 'lang.en.json';
import lang_se from 'lang.se.json';

export default class LangStore {

    constructor(root) {
        this.root = root;
    }

    @observable language = localStorage.getItem("language") || "fi";

    @action.bound setLanguage(language) {
        this.language = language;
        localStorage.setItem("language", this.language);
    }

    @computed get get() {

        switch(this.language) {

            case "fi":
            return lang_fi;

            case "en":
            return lang_en;

            case "se":
            return lang_se;

            default:    
            this.setLanguage("fi");
            return lang_fi;
        }

    }

    getErrorMessages(res) {

        if(typeof(res) !== 'object') {
            return false;
        }

        if(!("errors" in res)) {
            return false;
        }

        let errors = res.errors; 

        if(!Array.isArray(errors)) {
            return false;
        }

        return errors.map( (error) => {

            let message = "";

            if(error.code in this.get.errorcodes) {
                message = this.get.errorcodes[error.code];
            } else {

                Object.keys(this.get.errorcodes).forEach((errorcode) => {
                    if(error.code.match(new RegExp(errorcode))) {
                        message = this.get.errorcodes[errorcode];
                    }
                });

            }

            if(message === "") {
                message = error.message;
            }

            return message;

        });        
    }

    replace(string, data) {

        if(!data) {
            return string;
        }

        const entries = Object.entries(data);
        for(const [k, v] of entries) {
            string = string.replace("{{"+k+"}}", v);
        }

        return string;
    }

    // Get businessid in correct lang
    businessid(country) {

        if(country in this.root.lang.get.businessid) {
            return this.root.lang.get.businessid[country];
        }

        return this.root.lang.get.businessid["global"];
    }
}
