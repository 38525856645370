import React, { Component } from 'react';
import AppUser from 'components/smart/App/AppUser';
import AppLogin from 'components/smart/App/AppLogin';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import './App.scss';
import qs from 'query-string';

@withRouter
@inject("ui", "auth", "customers", "organizations") 
@observer
class App extends Component {

    constructor(props) {

        super(props);

        this.props.history.listen((location) => {
            this.props.ui.transition(location.pathname);
            this.props.ui.closeMenu();
        });
    }

    async componentDidMount() {

        // Get and parse query params
        const params = qs.parse(this.props.location.search);

        // Predefined access token
        if(params && params.jwt) {

            // Try to get fresh token
            const res = await this.props.auth.put(
                {jwt: params.jwt},
                {renew: false}
            );

            // Set if success
            if(this.props.auth.isResponseOk(res)) {
                this.props.auth.setJwt(res.data.jwt);
                this.props.auth.setId(res.data.id);
            }

            // Replace jwt from address bar to avoid bookmarking
            this.props.history.replace(this.props.location.pathname);

            // See if there is keyword
            if(params && params.keyword) {

                // Get all customers
                await this.props.customers.get();

                // Set keyword
                this.props.ui.setKeyword(params.keyword);

                // See if we have only one matching customer
                const searchResults = this.props.customers.list.filter((v) => {

                    if(v.is_removed === true) {
                        return false;
                    }

                    // Prepare search string
                    const search = (v.name+"|"+v.address+"|"+v.postalnumber+"|"+v.city+"|"+v.businessid+"|"+v.ssn+"|"+v.department+"|"+v.vatnumber).toLowerCase();

                     // Try to search
                    if(search.indexOf(this.props.ui.keyword.toLowerCase()) === -1) {
                        return false;
                    }

                    return true;
                });
       
                // See if we have only one result
                if(searchResults.length === 1) {

                    const results = searchResults[0];
                    const customers_id = results && results["id"];

                    // We have our targetted customer!
                    if(customers_id) {

                        // Push into customer card
                        this.props.history.push("/customers/" + customers_id);

                        // Even further: to identifications
                        if(params && params.identifications === "1") {

                            // See if we do not have organization data yet
                            if(!this.props.organizations.my) {
                                await this.props.organizations.getCurrent();
                            }   

                            // Push if identifications is ON
                            if(this.props.organizations.my.use_identifications === true) {
                                this.props.history.push("/customers/" + customers_id + "/identifications");
                                return true;
                            }
                        }

                        return true;
                    }
                }

                // There is more than 1 or 0 customers found with keyword. Just pass into (filtered) customers page
                this.props.history.push("/customers/");
                return true;
            } 
        }
    }

    render() {

        let modal = this.props.ui.nextModal;

        let content = <AppUser/>;

        if(this.props.auth.isLoggedIn === false) {
            content = <AppLogin/>;
        }

//      const loading = this.props.ui.isLoading === true && <div className="isLoading"><div className="loader"></div>;

        return (
            <div id="App" className="App">
                {modal}
                {content}
            </div>
        ); 
    }

}

export default App;

