import DoksApiStore from './DoksApiStore';
import { action, computed, runInAction } from 'mobx';
import api from 'api.json';

export default class RiskquestionStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.riskquestions;
        this.sort = "sort";
        this.sort2 = "question";
        this.questiontypes = ["YESNO", "TEXT", "TEXTAREA", "CHECKBOX", "SELECT", "MULTI"];
    }

    // Alias for POST
    @action.bound add(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.riskquestions;
        return this.post(data, settings);
    }

    // Alias for PUT
    @action.bound update(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.riskquestions + "/" + data.id;
        return this.put(data, settings);
    }

    // Alias for DELETE
    @action.bound remove(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.riskquestions + "/" + data.id;
        return this.delete(data, settings);
    }

    // Sorting questions
    @action.bound async sortQuestions(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.riskquestions;
        const res = await this.patch({data: data}, settings);

        if(res && res.status === true && res.data && res.data instanceof Array) {
            runInAction(() => {
                res.data.forEach((item) => {
                    if(data.indexOf(item.id) > -1) {
                        this.data.set(item.id, item);
                    }
                });
            });
        }

        return res;
    }

    // All unique types
    @computed get types() {

        let types = this.list.filter((el) => {
            return el.type;
        }).map((el) => {
            return el.type;
        }).filter((v,i,s) => {
            return s.indexOf(v) === i;
        }).sort();

        return types;
    }
}
