import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class StatementStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.statements;
        this.whistleblowers_id = false;
        this.sort = "created_at";
        this.desc = true;

    }

    @action.bound setWhistleblowersId(id) {

        if(id === this.whistleblowers_id) {
            return true;
        }

        this.whistleblowers_id = id;
        this.data.clear();
        this.url = api.endpoints.user.statements.replace(":id", id);

    }

    @action.bound async markAsReceived(id) {

        const res = await this.patch(
            {},
            {url: this.prepareUrl(this.url + "/" + id + "/received")}
        );

        return res;
    }

    @action.bound async markAsAnswered(id) {

        const res = await this.patch(
            {},
            {url: this.prepareUrl(this.url + "/" + id + "/answered")}
        );

        return res;
    }

}
