import React, { Component } from 'react';
import './Loading.scss';

class Loading extends Component {

    constructor(props) {
        super(props);
        this.state = {hidden : true};
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({hidden: false});
        }, this.props.wait > 0 ? this.props.wait : 750);
    }

    componentWillUnmount() {

        if(this.timer) {
            clearTimeout(this.timer);
            this.timer = 0;
        }
    }

    render() {

        if(this.state.hidden) { return null; }

return (<div className="spinner">
  <div className="bounce1"></div>
  <div className="bounce2"></div>
  <div className="bounce3"></div>
</div>);
    }
}

export default Loading;

