import DoksApiStore from './DoksApiStore';
import { observable, action, runInAction } from 'mobx';
import api from 'api.json';

export default class ActualbeneficiaryStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.actualbeneficiaries;
        this.customers_id = false;
        this.sort = "created_at";
        this.sort2 = "name";
    }

    @observable date = "";
    @observable source = "";

    @action.bound setCustomersId(id) {

        if(id === this.customers_id) {
            return true;
        }

        this.customers_id = id;
        this.data.clear();
        this.url = api.endpoints.user.actualbeneficiaries.replace(":id", id);

    }

    @action.bound setGroup = (date, source) => {
        runInAction(() => {
            this.date = date;
            this.source = source;
        });
    };
}
