import DoksApiStore from './DoksApiStore';
import { observable, action, runInAction } from 'mobx';
import api from 'api.json';

export default class AttachmentStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.attachments;
        this.sort = "name";
    }

    @observable uploadProgress = 0;
    @observable uploadFilename = "";

    async upload(file, cancelToken) {

        if(!file) {
            return false;
        }

        let data = new FormData();
        data.append("file", file);
        data.append("name", file.name);

        runInAction(() => {
            this.uploadFilename = file.name;
            this.uploadProgress = 0;
        });

        const res = await this.post(data, {
            url: api.endpoints.admin.attachments,
            cancelToken: cancelToken,
            progress: (e) => {

                runInAction(() => {
                    this.uploadProgress = parseInt(e.loaded/e.total*100, 10);
                });
            }
        });

        runInAction(() => {
            this.uploadProgress = 100;
        });

        return res;
    }

    // Alias for POST
    @action.bound add(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.attachments;
        return this.post(data, settings);
    }

    // Alias for PUT
    @action.bound update(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.attachments + "/" + data.id;
        return this.put(data, settings);
    }

    // Alias for DELETE
    @action.bound remove(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.attachments + "/" + data.id;
        return this.delete(data, settings);
    }

    async file(id) {

        let attachment = this.data.get(id);

        if(attachment === false) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.files + "/" + attachment.files_id + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

}
