import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class SearchStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.search;
        this.keyword = false;
        this.sort = "name";

    }

    @action.bound async searchByKeyword(keyword) {

        this.data.clear();
        this.keyword = keyword;
        this.url = api.endpoints.user.search.replace(":keyword", encodeURIComponent(this.keyword));

        return this.get();

    }

}
