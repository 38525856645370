import React from 'react';
import './Modal.scss';

const Modal = (props) => {
    return <Modal.Fader onClick={props.onClick}><div className="Modal">{props.children}</div></Modal.Fader>
}

Modal.Fader = (props) => {
    return <div className="ModalFader" onClick={props.onClick}>{props.children}</div>
}

export default Modal;
