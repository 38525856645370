import DoksApiStore from './DoksApiStore';
import { action, observable, runInAction } from 'mobx';
import api from 'api.json';

export default class ResponsiblepersonStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.responsiblepersons;
        this.customers_id = false;
        this.sort = "created_at";
        this.sort2 = "name";
    }

    @observable date = "";
    @observable source = "";

    @action.bound setCustomersId(id) {

        if(id === this.customers_id) {
            return true;
        }

        this.customers_id = id;
        this.data.clear();
        this.url = api.endpoints.user.responsiblepersons.replace(":id", id);

    }

    @action.bound setGroup = (date, source) => {
        runInAction(() => {
            this.date = date;
            this.source = source;
        });
    };

    // Get roles / role codes by country
    roles(country, role) {

        // Fallback to global roles
        if(!["FI", "SE", "DK", "NO", "EE", "GB", "LV"].includes(country)) {
            country = "global";
        }
       
        // All roles 
        const roles = Object.keys(this.root.lang.get.responsiblepersons.roles[country]).map((key) => {
            return {"id": key, "name": this.root.lang.get.responsiblepersons.roles[country][key]};
        });

        // Add other option
        roles.push({"id": "other", "name": this.root.lang.get.responsiblepersons.roles.other});
    
        // Add role if not found
        if(role) {
            if(!(role in this.root.lang.get.responsiblepersons.roles[country])) {
                roles.push({"id": role, "name": role});
            }
        }

        return roles;
    }

    // Get role
    role(role) {

        for(const country of ["FI", "SE", "DK", "NO", "EE", "GB", "LV"]) {
            if(role in this.root.lang.get.responsiblepersons.roles[country]) {
                return this.root.lang.get.responsiblepersons.roles[country][role];
            }
        }

        if(role === "other") {
            return this.root.lang.get.responsiblepersons.roles.other;
        }

        return role;
    }
}
