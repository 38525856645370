import React from 'react';
import './Content.scss';

const Content = (props) => {

    let className = "Content";
    if(props.className) {
        className += ' ' + props.className;
    }
    
    if(props.withHeader) {
        className += ' withHeader';
    }

    if(props.withFooter) {
        className += ' withFooter';
    }

    return <div className={className}><div className="main">{props.children}</div></div>;
}

export default Content;


