import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class TeamStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.teams;
        this.sort = "name";
    }

    // Alias for POST
    @action.bound add(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.teams;
        return this.post(data, settings);
    }

    // Alias for PUT
    @action.bound update(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.teams + "/" + data.id;
        return this.put(data, settings);
    }

    // Alias for DELETE
    @action.bound remove(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.teams + "/" + data.id;
        return this.delete(data, settings);
    }

    // Add user
    @action.bound addUser(id, users_id) {
        let settings = {};
        settings.url = api.endpoints.admin.teams + "/" + id + "/users";
        return this.post({users_id: users_id}, settings);
    }    

    // Remove user
    @action.bound removeUser(id, users_id) {
        let settings = {};
        settings.url = api.endpoints.admin.teams + "/" + id + "/users";
        return this.delete({users_id: users_id}, settings);
    }
}

