import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';
import moment from 'moment';

export default class CustomerStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.customers;
        this.sort = "name";
    }

    @action.bound async updateFromBis(data) {

        let settings = {
            url: api.endpoints.user.bis.replace(":id", data.id)
        }

        return this.patch(data, settings);
    }

    @action.bound async checkBis(data) {

        let settings = {    
            url: api.endpoints.user.bis.replace(":id", data.id)
        }

        return this.put(data, settings);
    }

    @action.bound async linkCustomer(data) {

        let settings = {
            url: api.endpoints.user.linked_customers.replace(":id", data.id) + "/" + data.customers_id,
            method: "POST"
        }

        return this.fetch({}, settings);
    }

    @action.bound async unlinkCustomer(data) {

        let settings = {
            url: api.endpoints.user.linked_customers.replace(":id", data.id) + "/" + data.customers_id,
            method: "DELETE"
        }

        return this.fetch({}, settings);
    }

    @action.bound async toggleTag(data) {

        let settings = {
            url: api.endpoints.user.tag.replace(":id", data.id) + "/" + data.tags_id
        }

        return this.patch({}, settings);
    }

    @action.bound async changeUser(data) {

        let settings = {
            url: api.endpoints.admin.customers + "/" + data.id
        }

        return this.put(data, settings);
    }

    @action.bound async changeTeam(data) {

        let settings = {
            url: api.endpoints.user.customers + "/" + data.id + "/team"
        }

        return this.put(data, settings);
    }

    @action.bound async changeDomain(data) {

        let settings = {
            url: api.endpoints.user.customers + "/" + data.id + "/domain"
        }

        return this.put(data, settings);
    }

    @action.bound async removePermanently(data) {

        let settings = {
            url: api.endpoints.admin.customers + "/" + data.id
        }

        return this.delete(data, settings);
    }
    
    async suggest(search, country) {
        return this.fetch({}, {url: api.endpoints.common.companysearch + "/" + country + "/?s=" + encodeURIComponent(search)});
    }

    exists(data) {

        const matches = this.list.filter((v) => {
        
            if(v.name.toLowerCase() === data.name.toLowerCase()) {
                return true;
            }

            if(v.type === "business") {
                if(v.businessid) {
                    if(v.businessid === data.businessid) {
                        return true;
                    }
                }
            }

            if(v.type === "person") {
                if(v.ssn) {
                    if(v.ssn === data.ssn) {
                        return true;
                    }
                }
            }

            return false;

        });
        
        return matches.length > 0;

    }

    // Mark task as complete 
    @action.bound async taskDone(data) {

        let settings = {
            url: api.endpoints.user.task.replace(":id", data.id) + "/" + data.tasks_id
        }

        return this.patch({"mode": "add"}, settings);
    }

    // Mark task as pending
    @action.bound async taskPending(data) {

        let settings = {
            url: api.endpoints.user.task.replace(":id", data.id) + "/" + data.tasks_id
        }

        return this.patch({"mode": "remove"}, settings);
    }

    // Toggle on monitoring on/off
    @action.bound async toggleMonitoring(id, onoff, monitor_emails) {

        let settings = {
            url: api.endpoints.user.monitors.replace(":id", id)
        }

        return await this.patch({"is_monitored": onoff, "monitor_emails": monitor_emails}, settings);

    }

    // Toggle on kyc clock on/off
    @action.bound async toggleKYCClock(id, onoff, kycclock_alarm_at) {

        let settings = {
            url: api.endpoints.user.kycclock.replace(":id", id)
        }

        return await this.patch({"is_kycclock": onoff, "kycclock_alarm_at": kycclock_alarm_at}, settings);

    }

    // Download csv
    async csv() {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(this.url + "/export/csv?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    // Download pdf
    async pdf() {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.reports + "/pdf/customers?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    // Summary
    async summary(id) {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(this.url + "/" + id + "/pdf?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    // Get all unique emails
    getUniqueEmails() {

        let emails = [];
    
        let customer = this.data.get(this.root.identifications.customers_id);
        if(!customer) { customer = this.data.get(this.root.esignatures.customers_id);   }
        if(!customer) { customer = this.data.get(this.root.requests.customers_id);      }
        if(!customer) { customer = this.data.get(this.root.letters.customers_id);        }

        if(customer) {
            if(customer.email) {
                emails.push(customer.email);
            }
        }

        this.root.identifications.list.forEach((el) => {
            if(el.is_manual !== true) {
                emails.push(el.email);
            }
        });

        this.root.signatories.list.forEach((el) => {
            emails.push(el.email);
        });

        this.root.requests.list.forEach((el) => {
            emails.push(el.email);
        });

        this.root.letters.list.forEach((el) => {
            emails.push(el.email);
        });

        return emails.filter((x, i, a) => a.indexOf(x) === i);
    }

    // Determine "traffic light"
    getTrafficLight = (target, items, metadata) => {

        // Save light amounts here
        let lights = {"grey": 0, "red": 0, "green": 0, "yellow": 0};

        switch(target) {

            case "is_monitored":
            if(items === true) {           lights["green"]++;      }
            if(items === false) {          lights["red"]++;        }
            break;

            case "kycclock_alarm_at":
            if(moment(items, "X").subtract(1, "month") < moment().startOf("day")) {
                lights["yellow"]++;
                items = [];
            }
            if(moment(items, "X") < moment().startOf("day")) {
                lights["red"]++;
                items = [];
            }
            if(moment(items, "X") > moment()) {
                lights["green"]++;
                items = [true];
            }
            break;

            case "is_kycclock":
            if(items === true) {           lights["green"]++;      }
            if(items === false) {          lights["red"]++;        }
            break;

            case "riskscores_level":
            if(items === "LOW") {           lights["green"]++;      }
            if(items === "REGULAR") {       lights["yellow"]++;     }
            if(items === "HIGH") {          lights["red"]++;        }
            break;

            // Customer BIS information
            case "bis":
            switch(metadata) {

                case "bis_prepaymentregister":
                case "bis_traderegister":
                case "bis_taxliability":

                if(items === true) {
                    lights["green"]++;
                    items = [true];     // leads to icon change
                }

                if(items === false) {
                    lights["red"]++;
                    items = [];         // leads to icon change
                }

                break;

                case "bis_employerregister":

                if(items === true) {
                    lights["green"]++;
                    items = [true];     // leads to icon change
                }

                if(items === false) {
                    lights["yellow"]++;
                    items = [];         // leads to icon change
                }

                break;

                default:
            }
            break;

            // Identifications
            case "identifications":
            items.forEach((v) => {

                let light = "grey";

                if(v.sent_at > 0) {
                    light = "red";
                }

                if(v.is_identified) {       // this if includes manual identifications!
                    light = "green";
                    if(this.root.organizations.my.use_identifications_accept === true) {
                        if(v.is_accepted !== true) {
                            light = "yellow";
                        }
                    }
                }

                lights[light]++;
            });

            break;

            // Requests
            case "requests":
            items.forEach((v) => {

                let light = "grey";

                if(v.sent_at > 0) {
                    light = "red";
                }

                if(v.is_answered) {
                    light = "green";
                    if(this.root.organizations.my.use_requests_accept === true) {
                        if(v.is_accepted !== true) {
                            light = "yellow";
                        }
                    }
                }

                lights[light]++;
            });

            break;

            // Letters
            case "letters":
            items.forEach((v) => {

                let light = "grey";

                if(v.sent_at > 0) {
                    light = "red";
                }

                if(v.is_opened) {
                    light = "green";
                }

                lights[light]++;
            });

            break;

            // Esignatures
            case "esignatures":
            items.forEach((v) => {

                let light = "grey";

                if(v.status === "SEND") {
                    light = "red";
                }
                if(v.status === "PAUSED") {
                    light = "yellow";
                }
                if(v.status === "SIGNED") {
                    light = "green";
                }

                lights[light]++;
            });

            break;

            // Signatories
            case "signatories":
            items.forEach((v) => {

                let light = "grey";
                if(metadata && metadata.status !== "DRAFT") {
                
                    if(v.sent_at > 0) {
                        light = "red";
                    }
                    if(v.signed_at > 0) {
                        light = "green";
                    }
                }

                lights[light]++;
            });

            break;

            // Actual beneficiaries, Owners, Responsible persons, PEPs and Risk answers
            case "actualbeneficiaries":
            case "owners":
            case "responsiblepersons":
            case "peps":
            case "riskanswers":
            if(items.length > 0) {
                lights["green"]++;
            }
            break;

            // Risk assessments
            case "riskassessments":
            items.forEach((v) => {

                //let light = moment(v.created_at, "X") > moment().subtract(1, "year") ? "green" : "yellow";
                let light = "green";

                if(v.expires_at > 0 && moment(v.expires_at, "X") < moment().startOf("day")) {
                    light = "red";
                }

                lights[light]++;
            });

            break;

            // Todos
            case "todos":
            items.forEach((v) => {

                let light = "yellow";
                if(v.is_done === true) {
                    light = "green";
                }
                if(v.deadline_at) {
                    if(v.is_done === false) {
                        if(moment(v.deadline_at, "X") < moment().startOf("day")) {
                            light = "red";
                        }
                    }
                }

                lights[light]++;
            });

            break;

            // Documents
            case "documents":
            items.forEach((v) => {

                let light = "grey";
                if(v.expires_at && moment(v.expires_at, "X") < moment().startOf("day")) {
                    light = "red";
                }

                lights[light]++;
            });

            break; 

            // Alerts
            case "alerts":
            items.forEach((v) => {

                let light = "red";
                if(v.silence) {

                    switch(v.silence.silenceclass) {
                        case "TRUEPOSITIVE":
                        light = "red";
                        break;
                        case "FALSEPOSITIVE":
                        light = "green";
                        break;
                        case "EVALUATION":
                        light = "yellow";
                        break;
                        default:
                        light = "green";
                        break;
                    }
                }

                lights[light]++;
            });

            break;

            // Bank account numbers
            case "bankaccountnumbers":
            // always return grey
            break;
            
            default:
        }

        let results = {
            "icon": (items && items.length > 0 ? "check_box": "indeterminate_check_box"),
            "className": "",
            "type": "",
            "status": "draft",
            "checked": (items && items.length > 0 ? true: false)
        };

        if(lights.green > 0) {
            results.className = "success";
            results.status = "done";
        }

        if(lights.yellow > 0) {
            results.className = "wait";
            results.status = "wait";
        }

        if(lights.red > 0) {
            results.className = "error";
            results.status = "error";
        }

        results.type = results.className;

        return results;
    };
}
