import DoksApiStore from './DoksApiStore';
import { observable, action, computed, runInAction } from 'mobx';
import api from 'api.json';

export default class OrganizationStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.organizations;
    }

    @observable id = false;
    @observable logoUploadProgress = 0;
    @observable logoUploadFilename = "";
    @observable logoUploadTime = 0;

    isValidOrganizationsId(id) {

        if(!id) {
            return false;
        }

        if(!id.length || id.length !== 36) {
            return false;
        }

        return /^[a-z0-9-]+$/.test(id);
    }

    @action.bound getLogoUrl(id, cache) {

        if(this.isValidOrganizationsId(id) !== true) {
            return false;
        }

        return this.prepareUrl(api.endpoints.public.logo) + "/" + id + "?" + cache;
    }

    @computed get logo() {

        if(this.id === false) {
            return false;
        }

        return this.getLogoUrl(this.id, this.logoUploadTime);
    }

    @action.bound async getCurrent() {

        this.url = api.endpoints.user.organizations;

        const res = await this.get();

        if(this.isResponseOk(res)) {

            runInAction(() => {
                this.id = res.data.id;
            });
        }

        return res;

    }

    @computed get my() {

        if(this.id) {
            return this.data.get(this.id);
        }

        return false;
    }

    @action.bound update(data, settings) {

        this.url = api.endpoints.admin.organizations;
        return this.put(data, settings);
    }

    async uploadLogo(file, cancelToken) {

        if(!file) {
            return false;
        }

        let data = new FormData();
        data.append("file", file);

        runInAction(() => {
            this.logoUploadFilename = file.name;
            this.logoUploadProgress = 0;
        });

        const res = await this.post(data, {
            url: api.endpoints.admin.logo,
            cancelToken: cancelToken,
            progress: (e) => {

                runInAction(() => {
                    this.logoUploadProgress = parseInt(e.loaded/e.total*100, 10);
                });
            }
        });

        runInAction(() => {
            this.logoUploadProgress = 100;
            this.logoUploadTime = Math.random().toString(36);
        });

        return res;
    }

    // KYC plan
    async kycplan() {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(this.url + "/" + this.id + "/kycplan?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }
}
