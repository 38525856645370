import DoksApiStore from './DoksApiStore';
import api from 'api.json';

export default class QueryStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.queries;
        this.sort = "created_at";
        this.desc = true;

    }

    // PDF
    async pdf(id) {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(this.url + "/" + id + "/pdf?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    async dsa(id) {

        if(!id) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.dsa + "/" + id + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

}
