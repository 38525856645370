import React from 'react';
import Icon from 'components/dumb/Icon/Icon';
import Flex from 'components/dumb/Flex/Flex';
import './Header.scss';

const Header = (props) => {

    let className = "Header";
    if(props.className) {
        className += ' ' + props.className;
    }

    return <Flex align="center" noWrap className={className}>{props.children}</Flex>;
}

Header.Column = (props) => {

    let icon = props.icon && <Flex.Column size="min" onClick={props.onClick} className="column min icon"><Icon name={props.icon}/> </Flex.Column>;
    let title = props.title && <Flex.Column size="max" className="column max"><h2 className="title">{props.title}</h2></Flex.Column>;

    return <React.Fragment>{icon}{title}</React.Fragment>;
}

export default Header;


