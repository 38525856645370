import React, { Component } from 'react';
import Login from 'components/smart/Login/Login';
import Forgot from 'components/smart/Forgot/Forgot';
import { Switch, Route, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import './App.scss';

@withRouter
@inject("ui") 
@observer
class AppLogin extends Component {

    render() {

        return (
            <Switch>
                <Route path="/forgot" component={Forgot}/>
                <Route path="/" component={Login}/>
            </Switch>
        ); 
    }

}

export default AppLogin;

