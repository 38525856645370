import { computed } from 'mobx';
import DoksApiStore from './DoksApiStore';
import api from 'api.json';

export default class AnnouncementStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.announcements;
        this.sort = "created_at";
        this.desc = true;

    }

    @computed get latest_at() {

        if(this.cnt === 0) {
            return 0;
        }

        return this.list.reduce((prev, current) => (prev.created_at > current.created_at) ? prev : current).created_at;
    }
}
