import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class UserStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.users;
        this.sort = "name";
    }

    @action.bound getAvatarUrl(id) {
        return this.prepareUrl(api.endpoints.public.avatar) + "/" + id;
    }

    @action.bound async sendManual(id) {

        let settings = {
            url: api.endpoints.admin.manual.replace(":id", id)
        }

        return this.post({}, settings);
    }

    // Alias for POST
    @action.bound add(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.users;
        return this.post(data, settings);
    }

    // Alias for PUT
    @action.bound update(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.users + "/" + data.id;
        return this.put(data, settings);
    }

    // Alias for DELETE
    @action.bound remove(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.users + "/" + data.id;
        return this.delete(data, settings);
    }
}
