import React, { Component } from 'react';
import Modal from 'components/dumb/Modal/Modal';
import Card from 'components/dumb/Card/Card';
import Loading from 'components/smart/Loading/Loading';
import { inject, observer } from 'mobx-react';
import './Init.scss';

@inject("ui", "auth", "lang", "profile", "organizations", "customers")
@observer
class Init extends Component {

    constructor(props) {
        super(props);
        this.state = {hidden : true};
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({hidden: false});
        }, this.props.wait > 0 ? this.props.wait : 500);
    }

    componentWillUnmount() {

        if(this.timer) {
            clearTimeout(this.timer);
            this.timer = 0;
        }
    }

    render() {

        if(this.state.hidden) { return null; }

        if((this.props.customers.isSomethingLoaded === true && this.props.customers.cnt > 1) || this.props.customers.isLoading === false) {
            return null;
        }

        return (
            <Modal><Card className="Init"
                title={this.props.lang.get.init.title}
                subtitle={this.props.lang.get.init.subtitle}
                footer={this.props.lang.get.init.footer}><Loading wait={1}/></Card></Modal>
        );
    }

}

export default Init;
