import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class RiskscoreStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.riskscores;
        this.customers_id = false;
        this.sort = "created_at";
        this.desc = true;
    }

    @action.bound setCustomersId(id) {

        if(id === this.customers_id) {
            return true;
        }

        this.customers_id = id;
        this.data.clear();
        this.url = api.endpoints.user.riskscores.replace(":id", id);

    }
}
