import React from 'react';
import Icon from 'components/dumb/Icon/Icon';
import Flex from 'components/dumb/Flex/Flex';
import './Button.scss';

const Button = (props) => {

    let classNames = 'Button';

    let icon = props.icon && <Flex.Column size="min" className="icon"><Icon name={props.icon}/></Flex.Column>;
    let text = props.text && <Flex.Column size="max" className="text">{props.text}</Flex.Column>;

    if(props.color) {
        classNames += ' color-' +props.color;
    }

    if(props.size) {
        classNames += ' size-' + props.size;
    }

    if(!text) {
        classNames += ' type-icon';
    }

    if(props.className) {
        classNames += ' ' + props.className;
    }

    if(props.important) {
        classNames += ' important';
    }

    if(props.warning) {
        classNames += ' warning';
    }

    return <Flex align="center" noWrap={true} className={classNames} onClick={props.onClick}>{icon}{text}</Flex>;

}

export default Button;

