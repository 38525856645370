import React, { Component } from 'react';
import Card from 'components/dumb/Card/Card';
import Form from 'components/dumb/Form/Form';
import Button from 'components/dumb/Button/Button';
import Dialog from 'components/smart/Dialog/Dialog';
import LanguageChoose from 'components/smart/LanguageChoose/LanguageChoose';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import './Forgot.scss';
import logo from 'logo.svg';
import qs from 'query-string';

@inject("ui", "lang", "auth", "organizations") 
@observer
class Forgot extends Component {

    submit = async (data) => {

        const res = await this.props.auth.forgot(data.email);

        if(this.props.auth.isResponseFailed(res)) {
            let errors = this.props.lang.getErrorMessages(res);
            this.props.ui.openModal(<Dialog type="alert" title={this.props.lang.get.dialog.error} subtitle={this.props.lang.get.forgot.title} description={errors} animation="error"/>);
        }

        if(this.props.auth.isResponseOk(res)) {
            this.props.ui.openModal(<Dialog type="alert" title={this.props.lang.get.forgot.title} subtitle={data.email} description={this.props.lang.get.forgot.success} animation="success" autoDoneTimeout={1500}/>);
        }

    }

    render() {

        const params = qs.parse(this.props.location.search);

        const logoUrl = (params && params.organizations_id && this.props.organizations.isValidOrganizationsId(params.organizations_id)) ? this.props.organizations.getLogoUrl(params.organizations_id): logo;
        const loginUrl = (params && params.organizations_id && this.props.organizations.isValidOrganizationsId(params.organizations_id)) ? "/?organizations_id=" + params.organizations_id : "/";

        return (

            <div className="Forgot">

                <Card>

                    <img className="logo" src={logoUrl} alt={this.props.lang.get.common.name}/>

                    <Form onSubmit={this.submit}>

                        <Form.Input required type="email" name="email" id="email" autoComplete="username" label={this.props.lang.get.forgot.email}/>
                        <Form.Submit><Button color="grey" icon="cached" text={this.props.lang.get.forgot.reset}/></Form.Submit>

                        <Link to={loginUrl}>{this.props.lang.get.forgot.login}</Link>

                    </Form>

                    <LanguageChoose/>

                </Card>

            </div>

        );

    }
}

export default Forgot;

