import DoksApiStore from './DoksApiStore';
import api from 'api.json';

export default class CountryStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.common.countries;
        this.sort = "name";
    }

    isHighRiskCountry = (code) => {

        return this.list.filter((el) => {
            return el.code === code && el.risk && el.risk === "high";
        }).length > 0;
    
    }

    isMediumRiskCountry = (code) => {

        return this.list.filter((el) => {
            return el.code === code && el.risk && el.risk === "medium";
        }).length > 0;

    }


}

