import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class SignatoryStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.signatories;
        this.customers_id = false;
        this.esignatures_id = false;
        this.sort = "signorder";
        this.sort2 = "name";
    }

    @action.bound setCustomersIdAndEsignaturesId(customers_id, esignatures_id) {

        if(customers_id === this.customers_id) {
            if(esignatures_id === this.esignatures_id) {
                return true;
            }
        }

        this.customers_id = customers_id;
        this.esignatures_id = esignatures_id;
        this.data.clear();
        this.url = api.endpoints.user.signatories.replace(":customers_id", customers_id).replace(":esignatures_id", esignatures_id);

    }

    @action.bound async resend(id) {

        const res = await this.patch(
            {},
            {url: this.prepareUrl(this.url + "/" + id)}
        );

        return res;
    }

    preview(id) {
        const url = this.prepareUrl(api.endpoints.preview.signatories.replace("{{host}}", this.root.organizations.my.host).replace(":organizations_id", this.root.organizations.id).replace(":customers_id", this.customers_id).replace(":esignatures_id", this.esignatures_id).replace(":id", id));
        window.open(url);
    }

    publicUrl(id) {
        return this.prepareUrl(api.endpoints.publicUrls.signatories.replace("{{host}}", this.root.organizations.my.host).replace(":organizations_id", this.root.organizations.id).replace(":customers_id", this.customers_id).replace(":esignatures_id", this.esignatures_id).replace(":id", id));
    }
}
