import React, { Component } from 'react';
import './LanguageChoose.scss';
import Icon from 'components/dumb/Icon/Icon';
import Flex from 'components/dumb/Flex/Flex';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import fiFlag from './fi.png';
import enFlag from './en.png';
import seFlag from './se.png';

@inject("ui", "lang", "profile") @observer
class LanguageChoose extends Component {

    @observable open = false;

    languages = [
        {"code": "fi", "image": fiFlag, "text": this.props.lang.get.languages.choose.fi},
        {"code": "en", "image": enFlag, "text": this.props.lang.get.languages.choose.en},
        {"code": "se", "image": seFlag, "text": this.props.lang.get.languages.choose.se}
    ];

    @action toggle = () => {
        this.open = !this.open;
    }

    choose = (code) => {
        this.props.lang.setLanguage(code);
        this.toggle();

        this.props.profile.updateLanguage(code);
    }
     
    render() {

        // Current language
        const current_language = this.languages.filter((v) => {
            return v["code"] === this.props.lang.language;
        })[0];
    
        // Optional languages
        const optional_languages = this.languages.filter((v) => {
            return v["code"] !== this.props.lang.language;
        }).map((v) => {

            return (
                <Flex align="center" className="option" noWrap onClick={() => this.choose(v.code)} key={v.code}>
                    <Flex.Column size="min"><img src={v.image} alt={v.code}/></Flex.Column>
                    <Flex.Column size="min" className="text">{v.text}</Flex.Column>
                </Flex>
            );
        });

        // Options
        const options = this.open === true && <div className="option">{optional_languages}</div>;

        return (

            <div className="LanguageChoose">

                <Flex align="center" noWrap onClick={this.toggle} className={this.open === true && "disabled"}>
                    <Flex.Column size="min"><img src={current_language["image"]} alt={current_language["code"]}/></Flex.Column>
                    <Flex.Column size="min" className="text">{current_language["text"]}</Flex.Column>
                    <Flex.Column size="min"><Icon name="arrow_drop_down"/></Flex.Column>
                </Flex>

                {options}

            </div>

        );
/*
        return (
            <div className="LanguageChoose">    
                <img className={this.props.lang.language !== "fi" ? "disabled" : undefined} src={fiFlag} alt="fi" onClick={() => this.props.lang.setLanguage("fi")}/>
                <img className={this.props.lang.language !== "en" ? "disabled" : undefined} src={enFlag} alt="en" onClick={() => this.props.lang.setLanguage("en")}/>
            </div>
        );
*/

    }
}

export default LanguageChoose;
