import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class WhistleblowerStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.whistleblowers;
        this.sort = "name";
    }

    // Alias for POST
    @action.bound add(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.whistleblowers;
        return this.post(data, settings);
    }

    // Alias for PUT
    @action.bound update(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.whistleblowers + "/" + data.id;
        return this.put(data, settings);
    }

    // Alias for DELETE
    @action.bound remove(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.whistleblowers + "/" + data.id;
        return this.delete(data, settings);
    }

    // Get all
    @action.bound getAsAdmin(data, settings) {

        if(!settings) {
            settings = {};
        }

        settings.url = api.endpoints.admin.whistleblowers;

        if(data && data.id) {
            settings.url = api.endpoints.admin.whistleblowers + "/" + data.id;
        }

        return this.get(data, settings);
    }

    // Reset and get
    @action.bound resetAndGet() {
        this.data.clear();
        return this.get();
    }
}

