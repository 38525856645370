import React from 'react';
import './Flex.scss';

const Flex = (props) => {

    let className = "Flex";

    if(props.className) {
        className += ' ' + props.className;
    }
    
    if(props.direction) {
        className += ' direction-' + props.direction;
    }

    if(props.align) {
        className += ' align-' + props.align;
    }

    if(props.noWrap) {
        className += ' nowrap';
    }

    return <div style={props.style} onClick={props.onClick} className={className}>{props.children}</div>;
}

Flex.Column = (props) => {

    let className = "FlexColumn";

    if(props.className) {
        className += ' ' + props.className;
    }

    if(props.size) {
        className += ' size-' + props.size;    
    }
    
    return <div onClick={props.onClick} className={className}>{props.children}</div>;
    
}

export default Flex;


