import DoksApiStore from './DoksApiStore';
import api from 'api.json';

export default class EIDStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.eids;
        this.sort = "id";

    }

    async file(id, files_id) {

        const eid = this.data.get(id);

        if(eid === false) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.files + "/" + files_id + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }
}
